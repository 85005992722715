<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue';
import { api_request } from '@cs/api_request';

const u_username = ref("");
const u_password = ref("");
const loading = ref(true);

async function login(){
  const u = u_username.value;
  const p = u_password.value;
  const result = await api_request('auth_login', {
    username: u,
    password: p,
  });
  if(result) {
    window.location.href = "/app/home";
  }
}

const username = ref<HTMLInputElement | null>(null);

(async function () {
  try {
    const auth_result = await api_request("auth_check", {});
    if(auth_result.logged_in) {
      window.location.href = "/app/home";
    } else {
      loading.value = false;
      nextTick(() => {
        if(username.value) {
          username.value.focus();
        }
      });
    }
  } catch(e) {
    console.error(e);
  }
})();

</script>

<template>
  <main v-if=loading>
    Loading...
  </main>
  <main v-else>
    <form @submit="$event.preventDefault()">
      <h3>Login Page</h3>
      <div>
        <label>
          Username:
          <input type="text" v-model=u_username ref=username autocomplete="username">
        </label>
      </div>

      <div>
        <label>
          Password:
          <input type="password" v-model=u_password autocomplete="current-password">
        </label>
      </div>

      <div>
        <button @click=login>Login</button>
      </div>
    </form>

  </main>
</template>

<style lang=scss scoped>
main {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

</style>